<template>
  <b-card class="w-100">
    <template #header>
      <h4>{{ $t('label.createNewBanner') }}</h4>
    </template>
    <BannerForm isNewForm @submit="submit"></BannerForm>
  </b-card>
</template>

<script>
  import BannerForm from '@/views/components/banner/BannerForm';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    BANNER_CREATE_BANNER,
    BANNER_INITIAL_CREATE_BANNER_STATE
  } from '@/core/store/banner.module';

  export default {
    name: 'BannerNew',
    components: {
      BannerForm
    },
    mixins: [commonMixin],
    computed: {
      createBannerComplete() {
        return this.$store.state.banner.createBanner.complete;
      }
    },
    watch: {
      createBannerComplete() {
        let response = this.$store.state.banner.createBanner;
        let title = i18nHelper.getMessage('label.createNewBanner');
        let initialStateAction = BANNER_INITIAL_CREATE_BANNER_STATE;
        let successAction = (response) => {
          this.createBannerSucceedAction(response);
        };
        let errorAction = () => {};

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    created() {
      this.initBannerNew();
    },
    methods: {
      submit(data) {
        this.createBanner(data);
      },
      createBannerSucceedAction() {
        this.$router.push({ name: ROUTE_NAME.BANNER_LIST });
      },
      createBanner(data) {
        this.$store.dispatch(BANNER_CREATE_BANNER, { data });
      },
      initBannerNew() {
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.banner'),
              route: { name: ROUTE_NAME.BANNER }
            },
            { title: i18nHelper.getMessage('label.newBanner') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>

<style></style>
